import React from "react";
import { images } from "../javascript/imageImports";
import { Link } from "react-router-dom";

const ChallengeDetails = () => {
  return (
    <section id="challengedetails" className="my-5 p-4 scroll-margin">
      <div className="fixed-background py-5">
        <div className="container challenge-details reveal-bottom">
          <div className="row my-5">
            <div className="col-md-12 mx-auto text-center text-muted">
              <h2 className="topic display-6 section-heading text-white text-uppercase">
                Challenge Details
              </h2>
              <hr className="light my-4" />
              <p className="my-4 text-light">
                Prepare yourselves for an engaging competition structured in two
                phases. The initial segment involves online participation
                through the utilization of the Webots robot simulation software,
                enabling control over virtual robots. In the subsequent and
                concluding phase, an in-person gathering will transpire.
                Notably, participants won't need to physically construct robots,
                as we'll supply the necessary robotic entities. Instead, the
                objective lies in successfully programming these robots to
                execute specific movements within a designated play area that we
                will provide.
              </p>
              <div>
                {/* <Link className="neon-button-two btn btn-outline-warning p-3 px-5 mt-3 mx-4 btn-lg reveal-bottom" to="#" target="_blank">Download the Software!</Link> */}
                <a
                  className="neon-button-two btn btn-outline-warning p-3 px-5 mt-3 mx-4 btn-lg reveal-bottom"
                  href="https://cyberbotics.com/"
                  target="_blank"
                >
                  Visit the Webots Site!
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* keep this empty and here. This is for the fixed background part */}
        <div className="fixed-wrap">
          <div
            className="fixed"
            style={{ backgroundImage: `url(${images.fixedBackground})` }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ChallengeDetails;
